import React, { Component } from 'react';

export default class App extends Component {
  state = {
    coins: [],
    sort: {
      "2020_percentage": true,
      "current_potential_up": true,
      "6months_potential_up_supply": true,
      "drop_potential_up": true,
      "drop_current_potential_up": true,
    }
  }

  componentDidMount(){
    this.init();
  }

  init = () => {
    const coeff = 1000 * 60 * 10;
    const date = new Date();
    const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);

    fetch(`data.json?v=${rounded.getTime()}`
    ,{
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
        }
    })
    .then( (res) => res.json() )
    .then( (json) => {
      this.setState({ coins: json });
    });
  }

  price = e => {
    let f = 0;
    let l = 0;
    e.forEach((item, ind) => {
      if(ind == 0) f = item.price;
      if(e.length-1 == ind) l= item.price
    });
    const per = (1 - l/f) * 100;
    return per;
  }

  sortArray = col => {
    let { coins, sort } = this.state;

    if(sort[col])
      coins = coins.sort((a,b) => parseFloat(a[col]) - parseFloat(b[col]));
    else
      coins = coins.sort((a,b) => parseFloat(b[col]) - parseFloat(a[col]));

    this.setState({
      coins,
      sort: {
        ...sort,
        ...{ [col]: !sort[col] }
      }
    });
  }

  sortStringArray = col => {
    let {coins, sort} = this.state;

    if(sort[col])
      coins = coins.sort((a,b) => (a[col] > b[col]) ? 1 : ((b[col] > a[col]) ? -1 : 0));
    else
      coins = coins.sort((a,b) => (a[col] < b[col]) ? 1 : ((b[col] < a[col]) ? -1 : 0));

    this.setState({ coins, sort: !sort[col]});
  }

  fixFloat = ( price ) => parseFloat(price).toFixed(2)

  render() {
    const { coins } = this.state;
    return <table>
      <thead>
        <tr>
          <th></th><th></th>
          <th colSpan="5" className="border-right">Last drop bigger than 30%</th>
          <th colSpan="4" className="border-right">Last 360days</th>
          <th></th><th></th><th></th><th></th>
        </tr>
        <tr>
          <th>Coin</th>
          <th>Current Price</th>
          <th>ATH Days</th>
          <th>ATL Days</th>
          <th>ATH $</th>
          <th>ATL $</th>
          <th className="pointer border-right" onClick={() => this.sortArray('drop_current_potential_up')}>Potential x</th>
          <th>ATH Days</th>
          <th>ATH $</th>
          <th className="pointer" onClick={() => this.sortArray('6months_potential_up')}>Potential x</th>
          <th className="pointer border-right" onClick={() => this.sortArray('6months_potential_up_supply')}>Supply x</th>
          <th>2018 ATH</th>
          <th>2020 ATL</th>
          <th className="pointer" onClick={() => this.sortArray('current_potential_up')}>x times till 2018 ATH</th>
          <th className="pointer" onClick={() => this.sortArray('current_potential_up_by_supply')}>x times till 2018 ATH<br />(Coin supply included)</th>
        </tr>
      </thead>
      <tbody>
        {coins.map(el => {
          const is_2020_low = el['2020_low'] == 9999999;
          return <tr key={el.id}>
            <td>{el.name} (<u>{el.symbol.toUpperCase()}</u>)</td>
            <td><strong><u>{this.fixFloat(el.current_price)}$</u></strong></td>
            <td>{el['recent_drop']['ath_days']}</td>
            <td>{el['recent_drop']['atl_days']}</td>
            <td>{this.fixFloat(el['recent_drop']['ath'])+"$"}</td>
            <td>{this.fixFloat(el['recent_drop']['atl'])+"$"}</td>
            <td className={`border-right ${ el['drop_current_potential_up'] > 1 ? "text-green" : "text-red"}`}>{el['recent_drop']['current_potential_up'] ? this.fixFloat(el['recent_drop']['current_potential_up'])+"x" : "Nera"}</td>
            <td>{el['6months_ath']['days_since_ath']}</td>
            <td>{this.fixFloat(el['6months_ath']['price'])+"$"}</td>
            <td className={`${ el['6months_potential_up'] > 1 ? "text-green" : "text-red"}`}>{el['6months_potential_up'] ? this.fixFloat(el['6months_potential_up'])+"x" : "Nera"}</td>
            <td className={`border-right ${ el['6months_potential_up_supply'] > 1 ? "text-green" : "text-red"}`}>{el['6months_potential_up_supply'] ? this.fixFloat(el['6months_potential_up_supply'])+"x" : "Nera"}</td>
            <td>{el['2018_ath'] ? this.fixFloat(el['2018_ath'])+"$" : "Nera"}</td>
            <td>{is_2020_low ? "Nera" : this.fixFloat(el['2020_low'])+"$"}</td>
            <td className={el['current_potential_up'] > 1 ? "text-green" : "text-red"}>{el['2018_ath'] ? this.fixFloat(el['current_potential_up'])+"x" : "Nera"}</td>
            <td className={el['current_potential_up_by_supply'] > 1 ? "text-green" : "text-red"}>{el['2018_ath'] ? this.fixFloat(el['current_potential_up_by_supply'])+"x" : "Nera"}</td>
          </tr>
        })}
      </tbody>
    </table>
  }
}